* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 2;
  font-size: 2.5rem;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

header {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-attachment: fixed;
  /* border: #000000 1px solid; */
}

.container {
  display: flex;
}

/*header and nav */

.logo {
  max-width: 22%;
  margin-bottom: -4%;
}

.title {
  display: flex;
}
.title-text {
  line-height: 1;
  padding-top: 3%;
  font-size: 2rem;
}

#shiba {
  padding-top: 2%;
}

#sensei {
  /* border: #000000 1px solid; */
  margin-left: 10%;
}

nav {
  display: flex;
  justify-content: center;
  background-color: #694a52;
  /* border: #000000 1px solid; */
}

nav a,
a {
  color: #ffffff;
  text-decoration: none;
  padding: 0 3%;
  font-size: 2.6rem;
  margin: 0 1%;
  /* border: #058585 1px solid; */
}

nav a:hover {
  color: #fbd7cc;
}

.header-title {
  padding: 1%;
  display: flex;
  /* border: #000000 1px solid; */
  justify-content: space-between;
}

/* .active {
  color: #058585;
} */

.auth-nav {
  color: #000000;
  text-decoration: none;
  margin: 0 4%;
  font-size: 2.6rem;
  display: flex;
  /* border: #000000 1px solid; */
}

.auth-nav a {
  color: #000000;
  text-decoration: none;
  font-size: 2.6rem;
  align-self: center;
  /* border: #000000 1px solid; */
}

.account-icon {
  display: flex;
  /* border: #000000 1px solid; */
  height: 3rem;
  width: 3rem;
}

/* end of header and nav */

button {
  background-color: azure;
  font-size: 2rem;
  margin: 1%;
  padding: 0 2%;
  cursor: pointer;
}

.main-page {
  display: flex;
  padding: 1% 3%;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

/* lessons page */

.lessons-box a,
.reviews-box a {
  /* color: #ffffff; */
  text-decoration: none;
  width: 100%;
  font-size: 2.6rem;
  justify-content: center;
  display: flex;
  /* border: #000000 1px solid; */
}

.lesson-box {
  display: flex;
  height: 50vh;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
  /* border: #000000 1px solid; */
}

.lesson-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  /* border: red 1px solid; */
}

.disabled {
  color: #d3d3d3;
  cursor: default;
}

.review-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #694a52;
  color: #ffffff;
  margin: 0 5%;
  width: 100%;
  /* border: #9e0d0d 1px solid; */
}

.no-header-dashboard-button {
  cursor: pointer;
  font-size: 1.6rem;
  /* border: #000000 1px solid; */
}

/* reviews page */

.reviews {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: #e9f907 1px solid; */
}

.review-meaning,
.review-reading {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: #9e0d0d 1px solid; */
}

.review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
  /* border: none; */
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: 900;
  cursor: pointer;
}

.incorrect,
.incorrect .answer-input {
  display: flex;
  background-color: rgb(246, 172, 172);
  width: 100%;
}

.correct,
.correct .answer-input {
  display: flex;
  background-color: rgb(163, 246, 163);
  width: 100%;
  font-size: 3rem;
}

.neutral,
.neutral .answer-input {
  display: flex;
  background-color: #f8cfc2;
  width: 100%;
  font-size: 3rem;
  /* border: #4a76fa 1px solid; */
}

.message {
  padding-right: 2%;
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
}

.answer-input {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 3%;
  /* border: #9e0d0d 1px solid; */
}

input[name="answer"] {
  border: none;
  background-color: transparent;
  outline: none;
  /* border: #9e0d0d 1px solid; */
}

.kana-letters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  justify-items: reverse;
  /* background-color: #addfe8; */
  margin-top: 5%;
  /* border: #9e0d0d 1px solid; */
}

.kana-letters p {
  font-size: 5rem;
  margin: 2%;
  cursor: pointer;
  /* border: #9e0d0d 1px solid; */
}

/* dashboard page */

.lessons-reviews-box {
  display: flex;
  width: 100%;
  padding: 5%;
  /* border: #000000 1px solid; */
}

.lessons-box {
  display: flex;
  align-items: center;
  /* background-color: #79b599; */
  background-color: #e3816f;
  /* color: #ffffff; */
  justify-content: center;
  margin: 0 5%;
  width: 50%;
  border: #000000 1px solid;
}

.reviews-box {
  display: flex;
  align-items: center;
  background-color: rgb(244, 181, 93);
  justify-content: center;
  margin: 0 5%;
  width: 50%;
  border: #000000 1px solid;
}

.review-nav {
  display: flex;
  justify-content: space-between;
  margin: 2%;
}

.next-review {
  display: flex;
  justify-content: center;
}

.toggle {
  font-size: 1.6rem;
  cursor: pointer;
}

.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: #000000 1px solid; */
  margin: 2%;
}

.progress {
  margin-left: 1%;
  background-color: #f8d8ce;
  display: flex;
  justify-self: center;
  align-self: center;
  /* border-radius: 20px; */
  height: 30px;
  width: 300px;
  /* border: #000000 1px solid; */
}

.progress-done {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: repeating-linear-gradient(
    to left,
    #d83957,
    #ff907c,
    rgb(244, 181, 93)
  );
  /* box-shadow: 0 5px 5px -6px #84b1ff, 0 3px 7px #1172f9; */
  /* border-radius: 20px; */
  color: #fff;
  height: 100%;
  width: 0;
  transition: 1s ease 0.3s;
  /* border: #000000 1px solid; */
}
/* vocab page */

.toRow {
  display: flex;
  border: #000000 1px solid;
}

.vocab-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* border: #000000 1px solid; */
}

.vocab-words {
  display: flex;
  flex-wrap: wrap;
  /* border: #98bfad 2px solid; */
  border: #ffc4b2 2px solid;
  width: 100%;
  margin-top: 0.5%;
  padding: 1%;
  /* background-color: #bbe9d4; */
  background-color: #fbd9cf;
  /* box-shadow: 0 5px 5px -6px #addfe8, 0 3px 7px #2d5288; */
  justify-content: space-between;
}

.vocab-right p {
  display: flex;
  justify-content: flex-end;
}

.select {
  color: #000000;
  background-color: transparent;
  border: none;
  width: 20%;
  font-size: 2.6rem;
  font-family: inherit;
}

form {
  display: flex;
  flex-direction: column;
  background-color: #fbd9cf;
  padding: 5%;
  margin: 2%;
  align-items: center;
  justify-content: center;
  /* border: #000000 1px solid; */
}

.checkbox {
  margin-left: 1%;
}

.settings,
.profile,
.danger-zone {
  display: flex;
  margin: 2%;
  padding: 5%;
  background-color: #fbd9cf;
  /* border: #000000 1px solid; */
}

.settings input[type="checkbox"] {
  margin: 1%;
}

.danger-zone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #e8adad;
  /* border: #000000 1px solid; */
}
/* reading page */

.paragraph-image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: #000000 1px solid; */
  margin: 3% 0;
}

.paragraph-image img {
  margin-left: 5%;
  height: auto;
}

li {
  list-style: none;
  /* border: #000000 1px solid; */
  margin-bottom: 2%;
}

@media (max-width: 768px) {
  .vocab-words {
    width: 100%;
    flex-direction: column;
  }

  .vocab-right p {
    justify-content: flex-start;
  }

  .select {
    width: 30%;
  }

  .paragraph-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3% 0;
  }
  .paragraph-image img {
    width: 80%;
    margin: 3%;
  }
}

@media (max-width: 608px) {
  .select {
    width: 35%;
  }
}

@media (max-width: 480px) {
  #hnavbuttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  .lessons-reviews-box {
    margin: 5% 3%;
    padding: 0;
  }

  .lessons-box {
    margin: 0;
    padding: 0;
  }

  .select {
    width: 50%;
  }
}

@media (max-width: 360px) {
  .select {
    width: 60%;
  }

  .lessons-reviews-box {
    flex-direction: column;
  }

  .lessons-box {
    width: 90%;
  }

  .reviews-box {
    width: 90%;
  }
}
